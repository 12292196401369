<template>
  <div class="homepage">
    <div class="b24">
      <HeroSection />
      <PillScrollerSection v-if="$auth.loggedIn" />
      <UserOrderSection v-if="isPersonalized && userHasOrders" />
    </div>
    <Top10WinesSection v-if="top10Wines" :top10-wines-array="top10Wines" />
    <HomeLogobanSection />
    <HomeWineclubSection />
    <ShopCollectionsSection :is-personalized="isPersonalized" />
    <HomeRestaurantsSection />
    <CustomerOpinionSection />
    <FeaturedCollectionsSection />
  </div>
</template>

<script>
import { showPersonalized } from '~/../common/services/customerServices'
export default {
  name: 'WineAccessHomepage',
  components: {
    FeaturedCollectionsSection: () =>
      import('~/components/Homepage/FeaturedCollectionsSection.vue'),
    CustomerOpinionSection: () =>
      import('~/components/Homepage/CustomerOpinionSection.vue'),
    HomeRestaurantsSection: () =>
      import('~/components/Homepage/HomeRestaurantsSection.vue'),
    ShopCollectionsSection: () =>
      import('~/components/Homepage/ShopCollectionsSection.vue'),
    HomeWineclubSection: () =>
      import('~/components/Homepage/HomeWineclubSection.vue'),
    HomeLogobanSection: () =>
      import('~/components/Homepage/HomeLogobanSection.vue'),
    Top10WinesSection: () =>
      import('~/components/Homepage/Top10WinesSection.vue'),
    UserOrderSection: () =>
      import('~/components/Homepage/UserOrderSection.vue'),
    PillScrollerSection: () =>
      import('~/components/Homepage/PillScrollerSection.vue'),
    HeroSection: () => import('~/components/Homepage/HeroSection.vue'),
  },
  data() {
    return {
      userHasOrders: false,
      top10Wines: [],
      preloadHeroImage: '',
    }
  },
  head() {
    // allow dynamically head updates
    return this.dynamicHead
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    },
    isPersonalized() {
      return showPersonalized(this.$auth)
    },
    dynamicHead() {
      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.preloadHeroImage,
            key: 'hero-preload',
          },
        ],
      }
    },
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      // update hero images dynamically
      this.screenWidth = window.innerWidth
      this.updateHeroPreload()
      window.addEventListener('resize', this.updateHeroPreload)
    }
  },
  beforeDestroy() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      // removes custom event
      window.removeEventListener('resize', this.updateHeroPreload)
    }
  },
  // Runs before the component is mounted (before it's added to the DOM).
  async created() {
    try {
      const response = await this.$axios.get(
        '/api/homepage/homepage-hero-index-data'
      )
      this.userHasOrders = response.data.user_orders
      this.top10Wines = response.data.top_10_wines
    } catch (error) {
      console.error('Error fetching homepage hero index data:', error)
    }
  },
  methods: {
    updateHeroPreload() {
      // eslint-disable-next-line nuxt/no-env-in-hooks
      if (process.client) {
        this.screenWidth = window.innerWidth
        const cloudfrontBase =
          process.env.CLOUDFRONT_BASE || 'https://static.qa-wineaccess.com/'

        if (this.isPersonalized) {
          if (this.screenWidth >= 768) {
            // contemplates resolutions sizes equal or up to 1024
            this.preloadHeroImage = `${cloudfrontBase}images/home-personalized-large.webp`
          } else {
            this.preloadHeroImage = `${cloudfrontBase}images/home-personalized-small.webp`
          }
        } else if (this.screenWidth >= 1024) {
          this.preloadHeroImage = `${cloudfrontBase}images/home-large.webp`
        } else if (this.screenWidth >= 768) {
          this.preloadHeroImage = `${cloudfrontBase}images/home-medium.webp`
        } else {
          this.preloadHeroImage = `${cloudfrontBase}images/home-small.webp`
        }
      }
    },
  },
}
</script>
